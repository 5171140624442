import React, { useEffect, useState } from 'react';
import { useAuth } from '../services/auth.service';
import { default as ReactSelect } from "react-select";
import axios from 'axios';
import styled from '@emotion/styled';
import { isMobile } from 'react-device-detect';

export default function SidebarInfo(props) {
    const [name, setName] = useState(props.contact?.NombreCompleto ? props.contact?.NombreCompleto : props.contact.Celular);
    const [number, setNumber] = useState(props.contact.Celular);
    const [notas, setNotas] = useState(props.contact?.notas ? props.contact?.notas : "");
    const [etiquetas, setEtiquetas] = useState([]);
    const [selectedTags, setSelectedTags] = useState(props.contact?.etiquetas ? (props.contact?.etiquetas) : []);
    const [updating, setUpdating] = useState(false);
    const [ejecutivoAsignado, setEjecutivoAsignado] = useState(props.contact?.ejecutivoAsignado ? (props.contact?.ejecutivoAsignado) : []);
    const [sucursalChat, setSucursalChat] = useState(props.contact?.sucursalChat ? (props.contact?.sucursalChat) : []);
    const [cbp, setCBP] = useState(props.contact?.conversation ? (props.contact?.conversation) : []);
    const [templates, setTemplates] = useState([]);
    const [plantillaSeleccionada, setPlantillaSeleccionada] = useState([]);
    const { user, logout } = useAuth();
    const [ejecutivos, setEjecutivos] = useState([]);
    const [expirationTimer, setExpirationTimer] = useState([]);
    const [expirationWidth, setExpirationWidth] = useState([]);
    const [expirationType, setExpirationType] = useState([]);
    const [timer, setTimer] = useState(undefined);

    const ReactSelectElement = styled(ReactSelect)`
        .react-select__control {
            border: 1px solid #e2e8f0;
            border-radius: 0.375rem;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            padding: 0.5rem 1rem;
            color: #4a5568;
            background-color: #fff;
            transition: all 0.2s;
            &:hover {
                border-color: #ff0000;
            }
            &:focus {
                border-color: #cbd5e0;
                box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
                outline: 0;
            }
        }
        .react-select__value-container {
            padding: 0;
        }
        .react-select__placeholder {
            color: #a0aec0;
        }
        .react-select__single-value {
            color: #4a5568;
        }
        .react-select__indicator-separator {
            display: none;
        }
        .react-select__indicator {
            color: #a0aec0;
            &:hover {
                color: #4a5568;
            }
        }
        .react-select__menu {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
            color: #4a5568;
            background-color: #fff;
            border: 1px solid #e2e8f0;
            border-radius: 0.375rem;
            margin-top: 0.25rem;
            box-shadow: 0 0.5rem 1rem rgba(50, 50, 93, 0.1),

                0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
            &:hover {
                border-color: #cbd5e0;
            }
        }
        .react-select__menu-list {
            padding: 0;
        }
        .react-select__option {
            padding: 0.5rem 1rem;
            &:hover {
                background-color: #f7fafc;
            }
        }
        .react-select__option--is-selected {
            background-color: #edf2f7;
        }
        .react-select__option--is-focused {
            background-color: #edf2f7;
        }
        .react-select__multi-value {
            background-color: #e2e8f0;
            border-radius: 0.375rem;
            color: #4a5568;
            padding: 0.25rem 0.5rem;
            margin: 0.125rem;
        }
        .react-select__multi-value__label {
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 1.25rem;
        }
        .react-select__multi-value__remove {
            color: #a0aec0;
            &:hover {
                color: #4a5568;
            }
        }
    `;


    useEffect(() => {
        setName(props.contact?.NombreCompleto ? props.contact?.NombreCompleto : props.contact.Celular);
        setNumber(props.contact.Celular);
        setNotas(props.contact?.notas ? props.contact?.notas : "");
        setSelectedTags(props.contact?.etiquetas ? (props.contact?.etiquetas) : []);
        setEjecutivoAsignado(props.contact?.ejecutivoAsignado ? (props.contact?.ejecutivoAsignado) : []);
        setSucursalChat(props.contact?.sucursalChat ? (props.contact?.sucursalChat) : []);
        setCBP(props.contact?.conversation ? (props.contact?.conversation) : []);
        //set a timer to expiration time that updates every second the time left from max of 24 hours

        async function fetchEtiquetas() {
            try {
                let url = `${process.env.REACT_APP_BACKEND_API}/tag`;
                let config = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "x-access-token": user.accessToken,
                    },
                };

                const response = await axios.get(url, config);
                let newEtiquetas = response.data.map((etiqueta) => {
                    return { value: etiqueta._id, label: etiqueta.name, selected: false };
                }
                );
                setEtiquetas(newEtiquetas);

            } catch (error) {
                console.log(error);
            }
        }
        fetchEtiquetas();
        async function fetchTemplates() {
            try {
                let url = `${process.env.REACT_APP_BACKEND_API}/template`;
                let config = {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        "x-access-token": user.accessToken,
                    },
                };

                const response = await axios.get(url, config);
                let newTemplates = response.data.map((template) => {
                    let isImageOrVideo = template['image-video-select'] ? template['image-video-select'].value : null;
                    if (isImageOrVideo == "image") {
                        return { value: template._id, label: template.name, image: template.image, video: "" };
                    } else if (isImageOrVideo == "video") {
                        return { value: template._id, label: template.name, video: template.video, image: "" };
                    } else {
                        return { value: template._id, label: template.name, video: template.video, image: template.image };
                    }
                }
                );
                setTemplates(newTemplates);

            } catch (error) {
                console.log(error);
            }
        }
        fetchTemplates();
    }, [props.contact.Celular]);

    useEffect(() => {
        if (updating)
            updateContactInfo();
    }, [updating]);

    useEffect(() => {
        if (cbp.length > 0) {
            setExpirationTimer([]);
            setExpirationWidth([]);
            setExpirationType([]);
            if (timer)
                clearInterval(timer);
            setTimer(
                setInterval(() => {
                    expirationFunction();
                }, 10000)
            );
            const expirationFunction = () => {
                let tempExpirationTimer = [];
                let tempExpirationWidth = [];
                let tempExpirationType = [];
                let expiredTimers = 0;

                props.contact.conversation.forEach((conversation, idx) => {
                    let expirationTime = new Date(conversation.expiration_timestamp * 1000);
                    let now = new Date();
                    let timeLeft = expirationTime - now;
                    let skip = false;
                    if (timeLeft <= 0) {
                        //remove the element
                        tempExpirationTimer.splice(idx, 1);
                        tempExpirationWidth.splice(idx, 1);
                        expiredTimers++;
                        if (timer && expiredTimers == props.contact.conversation.length)
                            clearInterval(timer);
                        //skip
                        skip = true;
                    }
                    if (!skip) {
                        let hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        let minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

                        tempExpirationTimer.push(hours + "h " + minutes + "m");
                        tempExpirationWidth.push(parseInt(hours / 24 * 100) + "%");
                        tempExpirationType.push(conversation.origin.type);
                    }
                });
                setExpirationTimer(tempExpirationTimer.map((expirationTimer) => expirationTimer));
                setExpirationWidth(tempExpirationWidth.map((expirationWidth) => expirationWidth));
                setExpirationType(tempExpirationType.map((expirationType) => expirationType));

                if (props.contact.conversation.length == 0) {
                    if (timer)
                        clearInterval(timer);
                }
            }
            expirationFunction();

        } else {
            setExpirationTimer([]);
            setExpirationWidth([]);
            setExpirationType([]);
            if (timer)
                clearInterval(timer);
        }
    }, [cbp]);

    useEffect(() => {
        async function fetchEjecutivos() {
            const url = `${process.env.REACT_APP_BACKEND_API}/ejecutivo`;
            const config = {
                headers: {
                    'x-access-token': user.accessToken,
                    'Content-Type': 'application/json',
                }
            }
            const response = await axios.get(url, config)
                .then((data) => {
                    let newEjecutivos = data.data.map((ejecutivo) => {
                        return { value: ejecutivo._id, label: ejecutivo.firstName + " " + ejecutivo.lastName, selected: ejecutivoAsignado.includes(ejecutivo._id) };
                    });
                    setEjecutivos(newEjecutivos);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        fetchEjecutivos();
    }, [sucursalChat]);

    function updateContactInfo() {
        setUpdating(false);
        fetch(`${process.env.REACT_APP_BACKEND_API}/contacts/${number}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "x-access-token": user.accessToken,
            },
            body: JSON.stringify({
                wa_id: number,
                NombreCompleto: name,
                notas: notas,
                etiquetas: selectedTags,
                ejecutivoAsignado: ejecutivoAsignado,
                sucursalChat: sucursalChat,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log('Success:', data);
                props.onUpdateContact(number, { NombreCompleto: name, notas: notas, etiquetas: selectedTags, ejecutivoAsignado: ejecutivoAsignado, sucursalChat: sucursalChat }, props.contact.inboxtype);
            }
            );

    }

    return (
        <div className="w-2/5 border-l-2 px-5 hidden md:block" id="sidebarContainer">
            <div className="flex flex-col">
                <div className="font-semibold text-xl py-4">
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        //update the name when press ENTER
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                setUpdating(true);
                                e.target.blur();
                            }
                        }}
                    />
                </div>
                <img
                    src="/icon.png"
                    className="object-cover rounded-xl h-24"
                    alt=""
                />
                <div className="font-semibold py-4">{number}</div>
                <div className="font-light">
                    <textarea
                        onChange={(e) => setNotas(e.target.value)}
                        class="form-control
                            block
                            w-full
                            px-3
                            py-1.5
                            text-base
                            font-normal
                            text-gray-700
                            bg-white bg-clip-padding
                            border border-solid border-gray-300
                            rounded
                            transition
                            ease-in-out
                            m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder="Notas"
                        rows={5}
                        cols={5}
                        value={notas}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                setUpdating(true);
                                e.target.blur();
                            }
                        }}
                    />
                </div>
                <div className="text-sm py-4">
                    Etiquetas<br></br>
                    <ReactSelectElement
                        options={etiquetas}
                        value={selectedTags}
                        isMulti
                        onChange={(e) => {
                            setSelectedTags(e);
                            setUpdating(true);
                        }
                        }
                    />
                </div>
                <div className="text-sm py-4">
                    Ejecutivo Asignado<br></br>
                    <ReactSelect
                        options={ejecutivos}
                        value={ejecutivoAsignado}
                        isMulti
                        onChange={(e) => {
                            setEjecutivoAsignado(e);
                            setUpdating(true);
                        }
                        }
                    />
                </div>
                <div className="text-sm py-4">
                    Enviar Plantilla<br></br>
                    <ReactSelect
                        options={templates}
                        value={plantillaSeleccionada}
                        onChange={(e) => {
                            setPlantillaSeleccionada(e);
                            setUpdating(true);
                        }
                        }
                    />
                    {
                        plantillaSeleccionada != [] > 0 &&
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white mt-5 font-bold py-2 px-4 rounded"
                            onClick={(e) => props.onSelectTemplate(e, { name: plantillaSeleccionada.label, id: plantillaSeleccionada.value, image: plantillaSeleccionada.image, video: plantillaSeleccionada.video })}
                        >Enviar</button>
                    }
                </div>
                {
                    cbp &&
                    expirationTimer.map((expTimer, idx) => {
                        return (
                            <div className="text-sm py-4">
                                {expirationType[idx]} Session Expires in<br></br>
                                <div key={idx} className="w-full bg-gray-200 rounded-full dark:bg-gray-700">
                                    <div id="timeleft" className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full" style={{ width: expirationWidth[idx] }}>{expTimer}</div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}