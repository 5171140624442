import React, { useEffect, useState } from "react";
import { useAuth } from "../services/auth.service";
import axios from "axios";
import XLSX from "xlsx";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default function CustomExcel() {
    const { user } = useAuth();

    const [templates, setTemplates] = useState([]);

    const [selectedTemplate, setSelectedTemplate] = useState(null);

    const [readedData, setReadedData] = useState([]);

    //driverjs
    const driverObj = driver({
        showProgress: true,
        steps: [
            { element: "#recentfiles", popover: { title: 'Archivos recientes', description: "Selecciona un archivo reciente para cargarlo en la tabla, alternativamente puedes cargarlo mediante el boton cargar excel" } },
            { element: "#loadexcelfile", popover: { title: 'Cargar archivo', description: "Carga un archivo de excel desde tu ordenador, automaticamente se ingresa en la tabla, tambien puedes seleccionar un archivo reciente que hayas usado anteriormente" } },
            { element: "#selecttemplate", popover: { title: 'Seleccionar plantilla de WA', description: "Selecciona una plantilla de Whatsapp para enviar mensajes" } },
            { element: "#templatemessage", popover: { title: 'Plantilla Cargada', description: "Al seleccionar una plantilla automaticamente se muestra en esta sección, puedes seleccionar variables para agregarlas al mensaje" } },
            { element: "#selectejecutivo", popover: { title: 'Seleccionar ejecutivo', description: "Selecciona un ejecutivo para asignarlo a los mensajes enviados" } },
            { element: "#columnnames", side: 'bottom', align: 'end', popover: { title: 'Columnas para plantilla', description: "Haz click en el nombre de la columna que quieras agregar a tu plantilla de Whatsapp y se agregará la variable en el orden de selección" } },
            { element: "#selectphone", popover: { title: 'Columna de telefono', description: "Selecciona una columna que corresponda con el numero de telefono de los destinatarios" } },
            { element: "#checkbox-table-search-1", popover: { title: 'Seleccionar filas a enviar', description: "Selecciona una o varias filas a las que desees enviar mensajes" } },
            { element: "#sendtemplate", popover: { title: 'Enviar mensajes', description: "Envía los mensajes utilizando las filas de la tabla seleccionadas" } },
        ]
    })

    const readExcelFile = (file) => {
        toast.info(`Leyendo archivo ${file.name}`);
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, { type: "buffer" });

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            //append a new column to the data with the checkbox
            d = d.map((row) => {
                return {
                    ...row,
                    checked: false,
                    sheettitle: file.name,
                    messageStatus: [
                        {
                            id: 0,
                            sentTemplate: "Sin plantillas",
                            status: "not sent"
                        }
                    ]
                }
            });
            async function loadSheets() {
                const url = process.env.REACT_APP_BACKEND_API + "/customexcel";
                const config = {
                    headers: {
                        "x-access-token": user.accessToken,
                        "Content-Type": "application/json",
                    },
                };
                let result = await axios
                    .post(url, JSON.stringify({ data: d }), config)
                    .then((response) => {
                        return response;
                    }
                    )
                    .catch((error) => {
                        return error;
                    }
                    );
                if (result.data) {
                    toast.success(`Archivo ${file.name} cargado`);
                    setReadedData(result.data);
                    //set the sheets array with the new sheet
                    if (!sheets.includes(result.data[0].sheettitle)) {
                        setSheets([...sheets, result.data[0].sheettitle]);
                    }
                }
            }
            loadSheets();
        });
    }

    const dataColumnNames = (data) => {
        if (data.length > 0) {
            return Object.keys(data[0]);
        }
        return [];
    }

    const checkIndividual = (e, index) => {
        setReadedData(readedData.map((row, rowIndex) => {
            if (rowIndex === index) {
                return {
                    ...row,
                    checked: e.target.checked
                }
            }
            return row;
        }));
    }

    const checkAll = (e) => {
        setReadedData(readedData.map((row) => {
            return {
                ...row,
                checked: e.target.checked
            }
        }));
    }

    useEffect(() => {
        if (templates.length > 0) return;
        async function loadTemplates() {
            const url = process.env.REACT_APP_BACKEND_API + "/template";
            const config = {
                headers: {
                    "x-access-token": user.accessToken,
                    "Content-Type": "application/json",
                },
            };
            let result = await axios
                .get(url, config)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
            if (result.data) {
                //translate each template
                result.data = result.data.map((template) => {
                    if (template.body)
                        template.body = translate(template.body);
                    return template;
                })
                setTemplates(result.data);
            }
        }
        loadTemplates();

    }, [templates, user.accessToken]);

    const [selectedEjecutivo, setSelectedEjecutivo] = useState(null);
    const [ejecutivos, setEjecutivos] = useState([]);

    useEffect(() => {
        if (ejecutivos.length > 0) return;
        async function loadEjecutivos() {
            const url = process.env.REACT_APP_BACKEND_API + "/ejecutivo";
            const config = {
                headers: {
                    "x-access-token": user.accessToken,
                    "Content-Type": "application/json",
                },
            };
            let result = await axios
                .get(url, config)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
            if (result.data) {
                setEjecutivos(result.data);
            }
        }
        loadEjecutivos();

    }, [ejecutivos, user.accessToken]);

    const handleSelectedEjecutivo = (e) => {
        //search the ejecutivo object with the id
        setSelectedEjecutivo(ejecutivos.find((ejecutivo) => ejecutivo._id === e));
    }

    const [sheets, setSheets] = useState([]);

    useEffect(() => {
        if (sheets.length > 0) return;
        async function loadSheets() {
            const url = process.env.REACT_APP_BACKEND_API + "/customexcel";
            const config = {
                headers: {
                    "x-access-token": user.accessToken,
                    "Content-Type": "application/json",
                },
            };
            let result = await axios
                .get(url, config)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
            if (result.data) {
                setSheets(result.data);
            }
        }
        loadSheets();
    }, [sheets, user.accessToken]);

    const loadExcelFromAPI = (e) => {
        e.preventDefault();
        toast.info(`Cargando archivo ${e.target.value}`);
        async function loadSheets() {
            const url = process.env.REACT_APP_BACKEND_API + "/customexcel/" + e.target.value;
            const config = {
                headers: {
                    "x-access-token": user.accessToken,
                    "Content-Type": "application/json",
                },
            };
            let result = await axios
                .get(url, config)
                .then((response) => {
                    return response;
                })
                .catch((error) => {
                    return error;
                });
            if (result.data) {
                setReadedData(result.data);
                toast.success(`Archivo ${e.target.value} cargado`);
            }
        }
        loadSheets();
    }

    useEffect(() => {
        //change the body of the template with translate
        if (!selectedTemplate) return;
        const spans = document.querySelectorAll("span[contenteditable=true]");
        setSelectedVariables([]);
        spans.forEach((span) => {
            span.addEventListener("click", (e) => {
                //remove the selectedVariables item with the innerHTML
                setSelectedVariables(selectedVariables.filter((variable) => variable !== e.target.innerHTML));
            });
        }
        );
    }, [selectedTemplate]);

    function translate(text) {
        text = text.replace(/\{\{(\d+)\}\}/g, (match, number) => {
            return `<span data-type="variable" data-index="${number}" style="white-space: nowrap;">${number}</span>`;
        });
        // Add line breaks
        text = text.replace(/\n/g, '<br>');

        // Replace bold and italic formatting
        text = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
        text = text.replace(/_(.*?)_/g, '<i>$1</i>');

        // Combine remaining formatting
        //text = text.replace(/(<i>.*)(<b>.*<\/b>.*<\/i>)/g, '$2$1');


        return text;
    }

    const [selectedVariables, setSelectedVariables] = useState([]);

    useEffect(() => {
        if (selectedVariables.length === 0 | selectedTemplate === null) return;
        const spans = document.querySelectorAll("span[data-type=variable]");
        //get the current selectedVariables length
        const currentIndex = selectedVariables.length - 1;
        //check the maximum number of variables for the selectedTemplate
        if (currentIndex >= selectedTemplate.parameters.length) {
            toast.error("No puedes agregar más variables");
            setSelectedVariables(selectedVariables.filter((variable, index) => index !== currentIndex));
            return
        }
        spans[currentIndex].innerHTML = selectedVariables[currentIndex];

    }, [selectedVariables]);

    const [isSendingMessages, setIsSendingMessages] = useState(false);

    const sendTemplateMessage = (e) => {
        e.preventDefault();
        if (isSendingMessages) {
            return;
        }
        if (selectedVariables.length === 0) {
            toast.error("No hay variables seleccionadas");
            return;
        }
        if (readedData.filter((readData) => readData.checked === true).length === 0) {
            toast.error("No hay mensajes seleccionados");
            return;
        }
        //check if the length of variables is equal than selectedTemplate.parameters
        if (selectedVariables.length !== selectedTemplate.parameters.length) {
            toast.error("El número de variables seleccionadas no coincide con el número de variables de la plantilla");
            return;
        }
        if (!selectedPhoneColumn) {
            toast.error("No se ha seleccionado la columna de teléfonos");
            return;
        }
        toast.info(`Enviando ${readedData.filter((readData) => readData.checked === true).length} mensajes `);
        //validates selectedTemplate in case of the user changes the template during the sending process
        const selectedTemplateForSending = selectedTemplate;

        //rows length 
        const rowsLength = readedData.filter((readData) => readData.checked === true).length;
        //send message each 6 seconds
        readedData.filter((readData) => readData.checked === true).forEach((readData, index) => {
            setTimeout(() => {
                //first we update the contact ejecutivo asignado
                fetch(`${process.env.REACT_APP_BACKEND_API}/contacts/${readData[selectedPhoneColumn]}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        "x-access-token": user.accessToken,
                    },
                    body: JSON.stringify({
                        ejecutivoAsignado: { value: selectedEjecutivo._id, label: selectedEjecutivo.firstName + " " + selectedEjecutivo.lastName, selected: true },
                    }),
                })
                    .then((response) => response.json())
                    .then((data) => {
                        console.log('Success:', data);
                    }
                    );
                //now send the message
                fetch(process.env.REACT_APP_BACKEND_API + "/messages/sendtemplatemessage", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": user.accessToken,
                    },
                    body:
                        selectedTemplate.type !== "quickreply" ?
                            JSON.stringify({
                                templateID: selectedTemplateForSending.name,
                                to: readData[selectedPhoneColumn],
                                parameters: selectedVariables.map((parameter) => {
                                    return { type: "text", value: readData[parameter] };
                                }),
                                header: {
                                    image: selectedTemplateForSending.image,
                                },
                                buttonParametersIndex: selectedTemplateForSending.buttonparameterindex,
                                buttonParameters:
                                    selectedTemplateForSending.buttonparameters.length > 0 ?
                                        selectedButtonParameters.map((buttonparameter) => {
                                            return { type: "text", value: readData[buttonparameter] };
                                        }) : [],
                                subscribedTo: readData._id,
                                subscribedObjectId: "",
                            }) :
                            JSON.stringify({
                                templateID: selectedTemplateForSending.name,
                                to: readData[selectedPhoneColumn],
                                parameters: selectedVariables.map((parameter) => {
                                    return { type: "text", value: readData[parameter] };
                                }),
                                quickReplies: true,
                                dataId: `${readData._id} customexcel`,
                                header: {
                                    image: selectedTemplateForSending.image,
                                },
                                buttonParametersIndex: selectedTemplateForSending.buttonparameterindex,
                                buttonParameters:
                                    selectedTemplateForSending.buttonparameters.length > 0 ?
                                        selectedButtonParameters.map((buttonparameter) => {
                                            return { type: "payload", value: readData[buttonparameter] };
                                        }) : [],
                                subscribedTo: "",
                                subscribedObjectId: "",
                            })
                }).then((response) => {
                    if (response.ok) {
                        toast.success(`Mensaje enviado a ${readData[selectedPhoneColumn]}`);
                        //catch response.json() promise
                        Promise.resolve(response.json()).then((jsonRes) => {
                            if (jsonRes.messages[0])
                                setReadedData(readedData.map((row) => {
                                    if (row._id === readData._id) {
                                        return {
                                            ...row,
                                            messageStatus: row.messageStatus.filter((status) => status.id !== 0).concat({
                                                id: jsonRes.messages[0].id,
                                                sentTemplate: selectedTemplateForSending.name,
                                                status: jsonRes.messages[0].message_status
                                            }),
                                        }
                                    }
                                    return row;
                                }))
                            let updatedReadData = {
                                ...readData, messageStatus: readData.messageStatus.filter((status) => status.id !== 0).concat({
                                    id: jsonRes.messages[0].id,
                                    sentTemplate: selectedTemplateForSending.name,
                                    status: jsonRes.messages[0].message_status
                                })
                            }
                            updateRowAPI(updatedReadData)
                        }
                        );
                        //check if its the last message
                        if (index === rowsLength - 1) {
                            //uncheck all
                            setReadedData(readedData.map((row) => {
                                return {
                                    ...row,
                                    checked: false
                                }
                            }));
                            setIsSendingMessages(false);
                        }
                    } else {
                        toast.error(`Error al enviar mensaje a ${readData[selectedPhoneColumn]}`);
                    }
                })
            }, index * 6000);
        });
    }

    const updateRowAPI = (row) => {
        let rowCopyWithoutID = { ...row };
        delete rowCopyWithoutID._id;
        fetch(process.env.REACT_APP_BACKEND_API + "/customexcel/" + row._id, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user.accessToken,
            },
            body: JSON.stringify(rowCopyWithoutID),
        }).then((response) => {
            if (response.ok) {
                console.log("row updated");
            } else {
                console.log("error updating row");
            }
        });
    }

    const [selectedButtonParameters, setSelectedButtonParameters] = useState([]);

    const changeSelectedTemplate = (e) => {
        e.preventDefault();
        setSelectedTemplate(templates.find((template) => template._id === e.target.value));
    }

    const [selectedPhoneColumn, setSelectedPhoneColumn] = useState(null);

    const [search, setSearch] = useState("");

    return (
        <>

            <div className="flex flex-column">
                <div key={1} className="relative overflow-x-auto shadow-md sm:rounded-lg px-4 mt-4" style={{ flex: '3' }}>
                    <div className="flex flex-column sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between">
                        <div
                            className="flex items-center space-x-4 sm:space-x-0 sm:space-y-0 sm:flex-row flex-wrap gap-x-4 gap-y-0"
                        >
                            <select id="recentfiles" onChange={loadExcelFromAPI} className="text-sm text-gray-500 dark:text-gray-400 border border-gray-300 dark:border-gray-600 rounded-lg w-40 bg-gray-50 dark:bg-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 ">
                                <option>Archivos recientes</option>
                                {sheets.map((sheet, sheetIndex) => {
                                    return <option key={sheetIndex} value={sheet}>{sheet}</option>;
                                })}
                            </select>
                            <select id="selecttemplate" className="text-sm text-gray-500 dark:text-gray-400 border border-gray-300 dark:border-gray-600 rounded-lg w-80 bg-gray-50 dark:bg-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 " onChange={(e) => changeSelectedTemplate(e)}>
                                <option>Seleccionar plantilla</option>
                                {templates.map((template) => {
                                    return <option value={template._id}>{template.name}</option>;
                                })}
                            </select>
                            <select id="selectejecutivo" className="text-sm text-gray-500 dark:text-gray-400 border border-gray-300 dark:border-gray-600 rounded-lg w-80 bg-gray-50 dark:bg-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 " onChange={(e) => handleSelectedEjecutivo(e.target.value)}>
                                <option>Ejecutivo</option>
                                {ejecutivos.map((ejecutivo) => {
                                    return <option value={ejecutivo._id}>{ejecutivo.firstName} {ejecutivo.lastName}</option>;
                                })}
                            </select>
                            <select id="selectphone" className="text-sm text-gray-500 dark:text-gray-400 border border-gray-300 dark:border-gray-600 rounded-lg w-40 bg-gray-50 dark:bg-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 " onChange={(e) => setSelectedPhoneColumn(e.target.value)}>
                                <option>Telefono Col</option>
                                {dataColumnNames(readedData).map((columnName, columnNameIndex) => {
                                    if (columnName === "checked") return null;
                                    return <option key={columnNameIndex} value={columnName}>{columnName}</option>;
                                })}
                            </select>
                            <button id="sendtemplate" onClick={sendTemplateMessage} type="button" class="text-white bg-[#1c71bd] hover:bg-[#1c71bd]/90 focus:ring-4 focus:outline-none focus:ring-[#1c71bd]/50 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center dark:focus:ring-[#1c71bd]/55">
                                <svg fill="#ffffff" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px" stroke="#ffffff">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g id="surface1">
                                            <path d="M2,3v7.8L18,12L2,13.2V21l20-9L2,3z"></path>
                                        </g>
                                        <rect className="st0" style={{ fill: "none" }} width="24" height="24"></rect>
                                    </g>
                                </svg>
                                &nbsp;Enviar Plantilla
                            </button>
                            <button id="loadexcelfile" type="button" onClick={(e) => { document.getElementById("excelfile").click() }} class="text-white bg-[#00873a] hover:bg-[#00873a]/90 focus:ring-4 focus:outline-none focus:ring-[#00873a]/50 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center dark:focus:ring-[#00873a]/55">
                                <svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" fill="#000000">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <g>
                                            <path style={{ fill: "#ffffff" }} d="M25.162,3H16v2.984h3.031v2.031H16V10h3v2h-3v2h3v2h-3v2h3v2h-3v3h9.162 C25.623,23,26,22.609,26,22.13V3.87C26,3.391,25.623,3,25.162,3z M24,20h-4v-2h4V20z M24,16h-4v-2h4V16z M24,12h-4v-2h4V12z M24,8 h-4V6h4V8z"></path>
                                            <path style={{ fill: "#ffffff" }} d="M0,2.889v20.223L15,26V0L0,2.889z M9.488,18.08l-1.745-3.299c-0.066-0.123-0.134-0.349-0.205-0.678 H7.511C7.478,14.258,7.4,14.494,7.277,14.81l-1.751,3.27H2.807l3.228-5.064L3.082,7.951h2.776l1.448,3.037 c0.113,0.24,0.214,0.525,0.304,0.854h0.028c0.057-0.198,0.163-0.492,0.318-0.883l1.61-3.009h2.542l-3.037,5.022l3.122,5.107 L9.488,18.08L9.488,18.08z"></path>
                                        </g>
                                    </g>
                                </svg>
                                &nbsp;Cargar Archivo Excel
                            </button>
                            <button type="button" onClick={(e) => { driverObj.drive() }} class="text-white bg-[#dedede] hover:bg-[#dedede]/90 focus:ring-4 focus:outline-none focus:ring-[#dedede]/50 font-medium rounded-lg text-sm px-5 py-2 text-center inline-flex items-center dark:focus:ring-[#dedede]/55">
                                <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#ffffff" strokeWidth="2"></path> <path d="M10.5 8.67709C10.8665 8.26188 11.4027 8 12 8C13.1046 8 14 8.89543 14 10C14 10.9337 13.3601 11.718 12.4949 11.9383C12.2273 12.0064 12 12.2239 12 12.5V12.5V13" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M12 16H12.01" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                            </button>
                            <input id="excelfile" type="file" style={{ visibility: "hidden" }} accept=".xlsx" onChange={(e) => {
                                const file = e.target.files[0];
                                readExcelFile(file);
                            }
                            } />
                        </div>
                        <label for="table-search" className="sr-only">Search</label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 rtl:inset-r-0 rtl:right-0 flex items-cente</div>r ps-3 pointer-events-none">
                                <svg className="w-5 h-10 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                            </div>
                            <input type="text" onChange={(e) => setSearch(e.target.value)} id="table-search" className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-40 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search for items" />
                        </div>
                    </div>
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400" id="columnnames">
                            <tr>
                                <th scope="col" className="p-4" key={0}>
                                    <div className="flex items-center">
                                        <input id="checkbox-all-search" onChange={(e) => checkAll(e)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                        <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                    </div>
                                </th>
                                {
                                    dataColumnNames(readedData).map((columnName, columnNameIndex) => {
                                        if (columnName === "checked" | columnName === "sheettitle" | columnName === "_id") return null;
                                        return (
                                            <th key={columnNameIndex + 1} scope="col" className="px-6 py-3" onClick={(e) => {
                                                if (!selectedVariables.includes(columnName)) {
                                                    setSelectedVariables([...selectedVariables, columnName]);
                                                }
                                            }}>
                                                {columnName}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                readedData.map((row, rowIndex) => {
                                    if (search !== "" && !Object.values(row).some((value) => value.toString().toLowerCase().includes(search.toLowerCase()))) {
                                        return null;
                                    } else {
                                        return (
                                            <tr key={rowIndex} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                                <td className="w-4 p-4">
                                                    <div className="flex items-center">
                                                        <input id="checkbox-table-search-1" checked={row.checked} onClick={(e) => checkIndividual(e, rowIndex)} type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                        <label for="checkbox-table-search-1" className="sr-only">checkbox</label>
                                                    </div>
                                                </td>
                                                {
                                                    Object.keys(row).map((key, keyindex) => {
                                                        if (key === "checked" | key === "sheettitle" | key === "_id") return null;
                                                        if (key === "messageStatus") {
                                                            return (
                                                                <td key={keyindex} className="px-6 py-4">
                                                                    {
                                                                        row[key].map((status, statusIndex) => {
                                                                            return (
                                                                                <div key={statusIndex} className="flex flex-row">
                                                                                    <p>
                                                                                        <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">{status.sentTemplate}:{status.status}</span>
                                                                                    </p>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </td>
                                                            )
                                                        }
                                                        return (
                                                            <td key={key} className="px-6 py-4">
                                                                {row[key]}
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    }
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div key={2} className="flex-1 w-100 bg-[#e5ddd5]" style={{ backgroundImage: "url('/chatbg.png')" }}>
                    <div className="flex flex-col">
                        <div className="bg-white h-6 w-full">
                            <p className="text-center font-bold text-md">{selectedTemplate ? selectedTemplate.name : 'tu plantilla'}</p>
                        </div>
                        <div className="p-4">
                            {
                                selectedTemplate && (
                                    <div id="templatemessage" className="flex-1 w-full bg-white p-2 rounded-tr-lg rounded-b-lg w-[300px]">
                                        <img src={selectedTemplate.image} />
                                        <p style={{ fontFamily: 'arial', fontSize: '13px' }} dangerouslySetInnerHTML={{ __html: selectedTemplate.body }}></p>
                                        {
                                            selectedTemplate.buttonparameters && (
                                                selectedTemplate.buttonparameters.map((buttonparameter, index) => {
                                                    return (
                                                        <div className="flex flex-row border-t-[#000] px-10" key={index}>
                                                            <svg fill="#000ba3" height="15px" width="15px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.676 26.676" stroke="#000ba3"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M26.105,21.891c-0.229,0-0.439-0.131-0.529-0.346l0,0c-0.066-0.156-1.716-3.857-7.885-4.59 c-1.285-0.156-2.824-0.236-4.693-0.25v4.613c0,0.213-0.115,0.406-0.304,0.508c-0.188,0.098-0.413,0.084-0.588-0.033L0.254,13.815 C0.094,13.708,0,13.528,0,13.339c0-0.191,0.094-0.365,0.254-0.477l11.857-7.979c0.175-0.121,0.398-0.129,0.588-0.029 c0.19,0.102,0.303,0.295,0.303,0.502v4.293c2.578,0.336,13.674,2.33,13.674,11.674c0,0.271-0.191,0.508-0.459,0.562 C26.18,21.891,26.141,21.891,26.105,21.891z"></path> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </g> </g></svg>
                                                            <span className="ml-4">BOTON {index}</span>
                                                        </div>
                                                    )
                                                }
                                                )
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}
